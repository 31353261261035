/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: Poppins;
  src: url('./assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: PoppinsLight;
  src: url('./assets/fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: OpenSans;
  src: url('./assets/fonts/OpenSans-Regular.ttf');
}

.aligned-text {
  text-align: center;
//   letter-spacing: 1.25px;
  word-spacing: -1px;
}
